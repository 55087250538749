<template>
	<div>
		<div id="game"></div>

		<button id="btn-back-to-main-map" class="btn btn-primary">
			<i class="fa-solid fa-arrow-left"></i><span class="non-mobile-label">&nbsp;&nbsp;Area Utama</span>
		</button>

		<div id="mv-nav-bar">
			<button id="btn-more" class="btn">
				<img :src="asset('moreverse/img/more_icon_white.png')" width="30" />
			</button>
			<button id="btn-info" class="btn">
				<i class="fa-solid fa-circle-info fa-xl"></i>
			</button>
			<button id="btn-download-catalogue-prj" class="btn">
				<i class="fa-solid fa-book fa-xl"></i>
			</button>
			<button id="btn-cart" class="btn"  @click="openCart()">
				<i class="fa-solid fa-cart-shopping fa-xl"></i>
				<span v-if="variants.length > 0" class="mv-notif-badge position-absolute badge rounded-pill bg-danger">
					{{variants.length}}
				</span>
			</button>
			<button id="btn-message" class="btn" @click="openChats()">
				<i class="fa-solid fa-comment fa-xl"></i>
				<span v-if="inbox > 0" class="mv-notif-badge position-absolute badge rounded-pill bg-danger">
					{{inbox}}
				</span>
			</button>
			<button id="btn-notification" class="btn" @click="openNotif()">
				<i class="fa-solid fa-bell fa-xl"></i>
				<span v-if="notifications.length > 0" class="mv-notif-badge position-absolute badge rounded-pill bg-danger">
					{{notifications.length}}
				</span>
			</button>
			<button id="btn-sfx-toggle" class="btn">
				<i class="fa-solid fa-volume-high fa-xl"></i>
			</button>
		</div>

		<!-- <div id="mv-info-bar">
            <button id="btn-more" class="btn">
                <img :src="asset('moreverse/img/more_icon.png')" width="30" />
            </button>
            <button id="btn-info" class="btn">
                <i class="fa-solid fa-circle-info fa-xl"></i>
            </button>
        </div> -->

		<!-- Welcome Modal -->
		<div class="modal fade welcome" id="welcomeModal" aria-labelledby="welcomeModalLabel" aria-hidden="true" style="background:#000000cc;">
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content" style="background: transparent; box-shadow: none; border: 0px;">
					<div class="modal-body">
						<div id="tutorial">
							<div id="tutorial-inside">
								<div class="tutorial-slider">
									<div class="tutorial-slide">
										<img class="img-fluid" :src=" asset('moreverse/img/tut_1.webp') " />
									</div>
									<div class="tutorial-slide">
										<img class="img-fluid" :src=" asset('moreverse/img/tut_4.webp') " />
									</div>
									<div class="tutorial-slide">
										<img class="img-fluid" :src=" asset('moreverse/img/tut_3.webp') " />
									</div>
									<div class="tutorial-slide">
										<img class="img-fluid" :src=" asset('moreverse/img/tut_2.webp') " />
									</div>
								</div>
							</div>
							<button class="btn prev">
								<i class="fa-solid fa-chevron-left"></i>
							</button>
							<button class="btn next">
								<i class="fa-solid fa-chevron-right"></i>
							</button>
						</div>
					</div>
					<!-- <div class="modal-footer">
						<button
							id="welcome-dismiss-btn"
							type="button"
							class="btn btn-lg btn-primary"
							data-bs-dismiss="modal"
						>
							Let's Go!
						</button>
					</div> -->
				</div>
			</div>
		</div>

		<!-- Shopping Cart Modal -->
		<div class="modal fade shopping-cart" id="shoppingCartModal" aria-labelledby="shoppingCartModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="shoppingCartModalLabel">
							Keranjang Belanja
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div>
							<span v-for="item in variants" :key="'cart-item-'+item.id">
								<div class="row cart-item">
									<div class="col-auto">
										<div class="cart-item-image">
											<img v-if="item.r_uni_product_image" :src="item.r_uni_product_image.image_url" :alt="item.name_long" :title="item.name_long">
											<img v-else :src="noImage()" :alt="item.name_long" :title="item.name_long">
										</div>
									</div>
									<div class="col">
										<div class="cart-item-title">
											{{item.name_short}}

											<span style="float:right;">
												<button type="button" class="btn btn-secondary" @click="removeVariant(item)"><i class="ol-fa fas fa-times"></i> Hapus</button>
											</span>
										</div>

										<div v-if="!item.bundle_summary" class="cart-item-color">
											{{item.variant}}
										</div>
										<div v-else class="ol-cart-list__body-item-desc-type">
											<span v-for="detail in item.bundle_summary" :key="detail.id+'detail-bundle'"> {{detail.name_short}} <b>x</b> {{detail.quantity}}<br></span>	
										</div>
										<!-- <div class="cart-item-price">
										 Harga Bisa Ditanyakan ke Mitra Terdekat
										</div> -->
										<div class="cart-item-price" style="display:block">
											{{ item.valid_price_rp }}
										</div>
										<div class="cart-item-qty" style="display:block">
											<div class="input-group h-100">
												<span class="input-group-btn">
													<button type="button" class=" btn btn-secondary btn-minus" @click="min(item)"> <i class=" fa-solid fa-minus"></i> </button>
												</span>
												<input type="text" id="quantity" name="quantity" class="input-number" :value="item.quantity" min="1" max="100" readonly />
												<span class="input-group-btn"> 
													<button type="button" class=" btn btn-secondary btn-plus" @click="plus(item)"> <i class="fa-solid fa-plus"></i> </button> 
													</span>
											</div>
										</div>
										<div class="text-danger" style="display:block">
											<span v-if="item.quantity <= item.valid_stock">Stok tersisa &#60; {{item.valid_stock}} </span>
											<span v-else>Stok Habis!</span>
											
											<div class="cart-item-subtotal">
												{{item.subtotal_rp}}
											</div>
										</div>
									</div>
								</div>

							<!-- PRODUCT FREE -->
								<div class="row cart-item" v-if="item.r_uni_product_get_free">
									<div class="col-auto">
										<div class="cart-item-image">
											<img v-if="item.r_uni_product_get_free.r_uni_product_variants.r_uni_product_image" :src="item.r_uni_product_get_free.r_uni_product_variants.r_uni_product_image.image_url" :alt="item.r_uni_product_get_free.r_uni_product_variants.name_long" :title="item.r_uni_product_get_free.r_uni_product_variants.name_long">
											<img v-else :src="noImage()" :alt="item.r_uni_product_get_free.r_uni_product_variants.name_long" :title="item.r_uni_product_get_free.r_uni_product_variants.name_long">
										</div>
									</div>
									<div class="col">
										<div class="cart-item-title">
											{{item.r_uni_product_get_free.r_uni_product_variants.name_long}}
										</div>

										<div class="cart-item-color">
											<b> ({{item.r_uni_product_get_free.free_type}}) </b>
											<span style="color:grey"> dari {{item.name_short}} </span>
										</div>

										<div class="cart-item-price" style="display:block">
											Rp 0
										</div>
										<div class="cart-item-qty">
											<div class="input-group h-100">
												<span class="input-group-btn">
													<button type="button" class=" btn btn-secondary btn-minus" disabled> <i class=" fa-solid fa-minus"></i> </button>
												</span>
												<input type="text" id="quantity" name="quantity" class="input-number" :value="item.quantity" min="1" max="100" readonly />
												<span class="input-group-btn"> <button type="button" class=" btn btn-secondary btn-plus" disabled> <i class="fa-solid fa-plus" ></i> </button> </span>
											</div>
										</div>
										<div class="text-danger" style="display:block">
											<span v-if="item.quantity <= item.r_uni_product_get_free.r_uni_product_variants.valid_stock">Stok tersisa &#60; {{item.r_uni_product_get_free.r_uni_product_variants.valid_stock}} </span>
											<span v-else>Stok Habis!</span>
											
											<div class="cart-item-subtotal">
												Rp 0
											</div>
										</div>
									</div>
								</div>
							</span>
							<div class="row" style="display:block;">
								<div class="col-md-auto col-12 mb-2 mb-md-0 text-end cart-notes">
									<input type="text" v-model="cart.note" class="input-number form-control"
										placeholder="Tulis Pesan (opsional)" value="">
								</div>
								<div class="col grand-total">
									Total: {{this.grandtotal}}
								</div>
								
							</div>
							
						</div>
					</div>
					<div class="modal-footer">
						<a href="#" id="checkout-btn" type="button" class="btn btn-lg btn-primary" > Beli Sekarang </a>
					</div>
				</div>
			</div>
		</div>

		<!-- Shopping Location Modal -->
		<div class="modal fade shopping-location" id="shoppingLocationModal" aria-labelledby="shoppingLocationModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="shoppingLocationModalLabel">
							Konfirmasi
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 mb-4" style="font-size:20px;">
								Untuk melanjutkan, pilih metode pembelianmu dahulu
							</div>
						</div>
						<div class="row gx-2">
							<div class="col-6 d-grid">
								<a class="btn btn-lg btn-outline-secondary" href="/cart" target="_blank">Website</a>
							</div>
							<div class="col-6 d-grid">
								<button id="buy-from-physical-store-btn" type="button" class="btn btn-lg btn-primary"
                                data-bs-dismiss="modal">Toko Mitra</button>
							</div>
							
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- User Login Modal -->
		<div class="modal fade shopping-location" id="loginConfirmModal" aria-labelledby="loginConfirmModalLabel"
			aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="loginConfirmModalLabel">Konfirmasi</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 mb-4">
							Silahkan buat akun untuk bisa melanjutkan aktivitasmu.
							</div>
						</div>
						<div class="row gx-2">
							<!-- <div class="col-6 d-grid">
								<button type="button" class="btn btn-lg btn-outline-secondary"
									data-bs-dismiss="modal">Tutup</button>
							</div> -->
							<div class="col-12 d-grid">
								<a href="/login" target="_blank" class="btn btn-lg btn-primary">Buat Akun</a>
								<!-- <button type="button" >Buat Akun</button> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		

		<!-- Chat Modal -->
		<div class="modal fade chat" id="chatModal" aria-labelledby="chatModalLabel" aria-hidden="true" >
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="chatModalLabel">Chat</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
					</div>
					<div class="modal-body">
						<div class="chat-stream">
							<ul class="m-b-0">
								<li v-if="chats.loading_more" class="clearfix">
									<div style="text-align: center;" >
										<img :src="require('@/assets/loading.gif')" width="40px" />
									</div>
								</li>
								<span v-for="item in chats.data.slice().reverse()" :key="item.id" >
									<li class="clearfix">
										<div v-if="!item.admin_users_id" class="message other-message float-right mb-10" style="margin-bottom:15px">
											<div class="row"> <span class="sender col text-start" >You</span> 
											<span class="time col-auto">{{item.created_at}}</span> </div>
											<div class="message-content"> {{item.text}} </div>
										</div>
										<div v-else class="message my-message mb-10" style="margin-bottom:15px">
											<div class="row">
												<span class="sender col text-start">Tim Support More</span>
												<span class="time col-auto">{{item.created_at}}</span>
											</div>
											<div class="message-content"> {{item.text}} </div>
										</div>
										<br>
									</li>

									<li v-if="item.r_uni_products" :key="item.id+'_p'" class="clearfix">
										<div class="message other-message float-right proddetail" style="margin-bottom:15px">
											<img :srcset="item.r_uni_products.r_uni_product_images[0].image_url">
											<div>
												<h3 class="ol-home-product__content-item-list--item-title">
													<router-link :to="'/product/'+item.r_uni_products.slug">
														{{item.r_uni_products.name}}
													</router-link>
												</h3>
												<div class="ol-home-product__content-item-list--item-price">
													<span v-if="item.r_uni_products.discount_max && item.r_uni_products.discount_max != '0%' && item.r_uni_products.price_normal != 'Rp0' " class="ol-home-product__content-item-list--item-price-real">{{item.r_uni_products.price_normal}}</span>
													<small class="ol-home-product__content-item-list--item-price-discount">{{item.r_uni_products.price_range}}
													</small>
												</div>
											</div>
										</div>
										<br>
									</li>

									<li v-else-if="item.file" :key="item.id+'_g'" class="clearfix">
										<div v-if="!item.admin_users_id" class="message other-message float-right imgsend" style="margin-bottom:15px">
											<a :href="item.file" target="_blank">
												<img :srcset="item.file">
											</a>
										</div>
										<div v-else class="message other-message imgsend">
											<a :href="item.file" target="_blank">
												<img :srcset="item.file">
											</a>
										</div>
										<br>
									</li>
								</span>
								
							</ul>
						</div>
					</div>
					<div class="chat-message clearfix">
						<div class="input-group mb-0">
							<input type="text" class="form-control" placeholder="Ketik pesan..." v-on:keyup.enter="onEnter" v-model="chats.msg" />
							<button class="btn btn-secondary" type="button"> <i class="fa-solid fa-paper-plane"></i> </button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Notification Modal -->
		<div class="modal fade notification" id="notificationModal" aria-labelledby="notificationModalLabel" aria-hidden="true" >
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="notificationModalLabel">
							Pemberitahuan
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
					</div>
					<div class="modal-body">
						<div class="chat-stream">
							<ul class="m-b-0">
								<li v-for="item in notifications" :key="item.id+'_notif'" class="clearfix">
									<div class="alert alert-primary" role="alert">
										<div :class="'notification-unread-indicator bg-'+item.type"></div>
										<div class="notification-title">
											{{item.title}}
										</div>
										<div class="notification-desc">
											{{item.text}}
										</div>
										<div class="notification-time">
											<i class="fa-solid fa-clock"></i> {{item.date}}
										</div>
									</div>
								</li>
								
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>

		
		<!-- Activities Modal -->
		<div class="modal fade activities" id="activitiesModal" aria-labelledby="activitiesModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="activitiesModalLabel">
							Jadwal Event
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<div>
							<div class="row">
								<div class="col-12 col-sm-6 mb-3">
									<div class="input-group flex-nowrap">
										<select class="form-select" id="activity-time" v-model="filterActivity.duration">
											<option selected value="">Tampilkan Semua</option>
											<option value="1">Sedang Berjalan</option>
											<option value="2">Akan Datang</option>
										</select>
									</div>
								</div>
								<div class="col-12 col-sm-6 mb-3">
									<div class="input-group flex-nowrap">
										<select class="form-select" id="activity-category" v-model="filterActivity.category">
											<option selected value="">Kategori Acara</option>
											<option value="Promo Menarik">Promo Menarik</option>
											<option value="Challenge Berhadiah">Challenge Berhadiah</option>
											<option value="Live Streaming">Live Streaming</option>
										</select>
									</div>
								</div>
							</div>
							<section class="timeline">
								<div class="container">
									<div class="timeline-item" v-for="(item, key) in activity" :key="key">
										<div class="timeline-img"></div>
										<div class="timeline-content timeline-card js--fadeInLeft">
											<div class="timeline-img-header">
												<img v-if="item.image_url != null" :src="item.image_url" />
												<h2>{{ item.name }}</h2>
											</div>
											<!-- <div class="date">{{ item.date }}</div> -->
											<p>{{ item.description }}</p>
											<a v-if="item.link != null" class="btn-more" :href="item.link" target="_blank">Lihat Detail</a>
											<a class="btn-remind" href="javascript:void(0)" @click="openReminder(item.id)">Ingatkan Saya</a>
										</div>
									</div>

									<!-- <div class="timeline-item">
										<div class="timeline-img"></div>
										<div class="timeline-content timeline-card js--fadeInRight">
											<div class="timeline-img-header">
												<img src="https://cdn.morefurniture.id/BANNER/flashsale-mobile.webp" />
												<h2></h2>
											</div>
											<div class="date">22 April 2022</div>
											<p>Dapatkan informasi seputar MOREVERSE secara Live di Live Streaming Tiktok Olympic Grup di Tanggal 22 April 2022</p>
											<a class="btn-more" href="javascript:void(0)">Lihat Detail</a>
											<a class="btn-remind" href="javascript:void(0)">Ingatkan Saya</a>
										</div>
									</div> -->
								</div>
							</section>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Videotron Modal -->
		<div class="modal fade videotron" id="videotronModal" aria-labelledby="videotronModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="videotronModalLabel">Video Jadi Lebih Baik</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="videotron-container">
							<div class="ratio ratio-16x9">
								<iframe src="https://www.youtube.com/embed/CIDFPmG1fVI?rel=0" title="YouTube video"
									allowfullscreen></iframe>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Store Region Modal -->
		<div class="modal fade store" id="storeRegionModal" aria-labelledby="storeRegionModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="storeRegionModalLabel"></h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div id="storeRegionList" class="modal-body d-grid">

					</div>
				</div>
			</div>
		</div>

		<!-- Store Modal -->
		<div class="modal fade store" id="storeModal" aria-labelledby="storeModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="storeModalLabel">Store</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 col-sm-6 mb-3">
								<div class="input-group flex-nowrap">
									<span class="input-group-text" id="addon-wrapping" ><i class="fa-solid fa-search"></i> </span>
									<input id="store-search" type="text" class="form-control" placeholder="Nama Mitra" aria-label="search" aria-describedby="addon-wrapping" />
								</div>
							</div>
							<div class="col-12 col-sm-6 mb-3">
								<div class="input-group flex-nowrap">
									<select class="form-select" id="store-category"></select>
								</div>
							</div>
							<!-- <div class="col-12 col-sm-4 mb-3">
								
								<button id="btn-catalogue" class="btn btn-danger"  style="width:100%">
									<i class="fa-solid fa-book fa-xl"></i> Lihat Katalog Promo
								</button>
							</div> -->
							
						</div>
						<div class="row grid masonry" id="mitraList">
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Catalogue Modal -->
		<div class="modal fade catalogue" id="catalogueModal" aria-labelledby="catalogueModalLabel" aria-hidden="true" >
			<div class="modal-dialog modal-dialog-centered modal-lg">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="catalogueModalLabel">
							Katalog
						</h5>
						<button
							type="button"
							class="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div class="modal-body">
						<div class="text-center mb-3">
							<a class="btn btn-primary" id="btn-download-catalogue" target="_blank"><i class="fa-solid fa-cloud-arrow-down"></i> Download Katalog</a>
						</div>
						<div id="catalogue" class="bookContainer">
							<div class="flip-book" id="demoBookExample">
								<div class="page page-cover page-cover-top" data-density="hard">
									<div class="page-content">
										<div class="page-image"
											style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/cover.webp')">
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-1"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/01.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-2"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/02.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-3"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/3.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-4"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/4.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-5"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/5.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-6"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/6.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-7"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/7.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-8"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/8.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-9"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/9.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-10"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/10.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-11"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/11.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-12"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/12.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-13"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/13.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-14"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/14.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-15"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/15.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image page-image-16"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/1/16.webp')">
											</div>
										</div>
									</div>
									<div class="page">
										<div class="page-content">
											<div class="page-image"
												style="background-image: url('https://cdn.morefurniture.id/MORE/moreverse/catalog/cover-end.webp')">
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<!-- Shop Modal -->
		<div class="modal fade shop" id="shopModal" aria-labelledby="shopModalLabel" aria-hidden="true" >
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="shopModalLabel"> ROOM Products </h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 col-sm-6 mb-3">
								<div class="input-group flex-nowrap">
									<span class="input-group-text" id="addon-wrapping" ><i class="fa-solid fa-search"></i> </span>
									<input v-model="productList.q" id="shop-search" type="text" class="form-control" placeholder="Nama Produk / Paket" aria-label="search" aria-describedby="addon-wrapping" />
								</div>
							</div>
							<div class="col-12 col-sm-6 mb-3">
								<div class="input-group flex-nowrap">
									<select v-model="productList.is_bundle" class="form-select" id="shop-category" >
										<option value=""> Tampilkan Semua Kategori </option>
										<option value="0">Satuan</option>
										<option value="1">Paket</option>
									</select>
								</div>
							</div>
						</div>
						<div class="row">
							<div class="col-12">
								<div v-if="products.length > 0" class="row">
									<div v-for="item in products" :key="item.id" class="col-12 col-lg-4 col-xl-3 mb-4">
									<button class="shop-grid" @click="openProduct(item.slug)">
										<div class="card">
											<div class="ratio ratio-1x1">
												<div class="shop-image-container">
													<img :src="item.r_uni_product_images[0].image_url" class="card-img-top" alt="" />
												</div>
											</div>
											<div class="card-body">
												<div class="shop-grid-title" style="text-align:center;">
													{{item.name}}
												</div>
												<hr>
												<!-- <div class="shop-grid-text" style="text-align:center;">
													<b>Beli Produk</b>
												</div> -->
												<div class="shop-grid-text" style="display:block;">
													<span v-if="!item.price_normal || item.price_normal == 'Rp0'">
														<span class="shop-grid-new-price" > {{item.price_range}}</span >
													</span>

													<span v-else>
														<span class="shop-grid-old-price" style="margin-right:10px;" v-if="!item.price_range.includes('-')" ><del >{{item.price_normal}}</del ></span>
														<span class="shop-grid-new-price" >{{item.price_range}}</span>
													</span>
												</div>
											</div>
											<div class="shop-grid-disc" v-if="item.discount_max && item.discount_max != '0%'">-{{item.discount_max}}</div>
										</div>
									</button>
									</div>
								</div>	
								<div v-else v-show="!productList.loading" class="alert alert-info text-center" id="alert-products-info" role="alert">Data tidak ditemukan</div>
							</div>

							<div v-if="productList.loading"  class="col-12">
								<div class="row">
									<div class="col-12 col-lg-4 col-xl-3 mb-4"><SkeletonLoader height="345px"/></div>
									<div class="col-12 col-lg-4 col-xl-3 mb-4"><SkeletonLoader height="345px"/></div>
									<div class="col-12 col-lg-4 col-xl-3 mb-4"><SkeletonLoader height="345px"/></div>
									<div class="col-12 col-lg-4 col-xl-3 mb-4"><SkeletonLoader height="345px"/></div>
								</div>
							</div>
						</div>
						<div v-if="!productList.loading && products.length > 0" class="row">
							<div class="col-12 d-grid">
								<button class="btn btn-primary" @click="getProducts()">
									Produk Lainnya
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Shop Detail Modal -->
		<div class="modal fade shopDetail" id="shopDetailModal" aria-labelledby="shopDetailModalLabel" aria-hidden="true" >
			<div class="modal-dialog modal-dialog-centered modal-xl">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="shopDetailModalLabel">
							{{ getSelectedFinalVariant ? getSelectedFinalVariant.name_long : product.name}}
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div v-show="productDetail.loading" class="row">
							<div class="col-12 col-lg-6 mb-4"><SkeletonLoader height="345px"/></div>
							<div class="col-12 col-lg-6">
								<div class="row">
									<div class="col-12"> <SkeletonLoader height="100px"/> <br> </div>
									<div class="col-12"> <SkeletonLoader height="100px"/> <br> </div>
									<div class="col-12"> <SkeletonLoader height="100px"/> <br> </div>
								</div>
							</div>
						</div>
						<div v-show="!productDetail.loading" class="row">
							<div class="col-12 col-lg-6 mb-4">
								<div id="main-slider" class="splide">
									<div class="splide__track">
										<ul class="splide__list">
											<li v-for="image in product.r_uni_product_images" :key="'main-slider-'+image.id" class="splide__slide">
												<img :src="image.image_url"/>
											</li>
											
										</ul>
									</div>
								</div>
								<div id="thumbnail-slider" class="splide">
									<div class="splide__track">
										<ul class="splide__list">
											<li v-for="image in product.r_uni_product_images" :key="'thumb-slider-'+image.id" :class="'splide__slide thumb-slider-variant-'+image.uni_products_variants_id">
												<img :src="image.image_url"/>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div class="col-12 col-lg-6">
								<!-- <h3>Sofa Bricks Ottoman</h3> -->
								<div v-if="getSelectedFinalVariant &&  getSelectedFinalVariant.r_uni_product_variant_prices.ho_normal_rp != 'Rp0'" class="product-detail-old-price" style="display:block;">
									<del>{{getSelectedFinalVariant.r_uni_product_variant_prices.ho_normal_rp}}</del>
								</div>
								<div class="mb-2" style="display:none;">
									<b style="font-size:18px;">Harga Bisa Ditanyakan ke Mitra Terdekat</b><br>
									<button id="list-mitra-product" class="btn btn-outline-secondary add-to-cart px-4 py-2 mb-4" style="margin-top:10px;color: #fff;background-color: #354C7B;border-color: #354C7B;">
									Lihat Mitra
								</button>
								
								</div>
								<div class="mb-2" style="display:block;">
									<span class="product-detail-new-price">{{getSelectedFinalVariant ? getSelectedFinalVariant.valid_price_rp : product.price_range}}</span>
									<span class="product-detail-discount" v-if="getSelectedFinalVariant && getSelectedFinalVariant.valid_discount && getSelectedFinalVariant.valid_discount != '0%'">-{{getSelectedFinalVariant.valid_discount}}</span>
								</div>

								<p v-if="product.variants_available || product.colors_available" class="mb-2">Pilih {{ product.variants_available > 0 ? "Varian" : "Warna" }}:</p>
								<div v-if="product.variants_available || product.colors_available" class="mb-4" role="group" aria-label="Basic radio toggle button group">
									<span v-if="product.variants_available > 0">
										<span v-for="item in product.r_uni_product_variants" :key="'variant-'+item.id">
											<input type="radio" class="btn-check" name="btnradio" :id="'btnradio-'+item.id" @click="setVariant(1, item)" />
											<label style="margin:10px 0;" class="btn btn-outline-secondary" :for="'btnradio-'+item.id">{{item.variant}}</label>&nbsp;
										</span>
									</span>
									<span v-else>
										<span v-for="item in product.r_uni_product_variants" :key="'variant-color-'+item.id">
											<input type="radio" class="btn-check" name="btnradio" :id="'btnradio-'+item.id" @click="setVariant(2, item)" />
											<label style="margin:10px 0;" class="btn btn-outline-secondary" :for="'btnradio-'+item.id">{{item.color}}</label>&nbsp;
										</span>
									</span>
								</div>
								<div class="alert alert-danger" id="alert-cart-danger" style="display:none" role="alert"> Stok Habis! </div>
								<div class="alert alert-success" id="alert-cart-success" style="display:none" role="alert">Berhasil Ditambahkan ke Keranjang</div>

								<button v-if="!productDetail.loading2Cart" id="add-to-cart" @click="toCart()" class="btn btn-primary add-to-cart px-4 py-2 mb-4">
									<i class="fa-solid fa-cart-shopping"></i>
									Masukan Keranjang
								</button>
								<button v-else id="add-to-cart" class="btn btn-primary add-to-cart px-4 py-2 mb-4">
									<i class="fa fa-spinner fa-spin"></i>
									Masukan Keranjang
								</button>
								<button id="list-cart-product" class="btn btn-outline-secondary add-to-cart px-4 py-2 mb-4" style="margin-left:5px;color: #fff;background-color: #354C7B;border-color: #354C7B;" @click="openCartDetailProduct()">
									Lihat Keranjang
								</button>
								
								<div class="alert alert-info" id="alert-cart-info" style="display:none" role="alert">Link berhasil dicopy</div>
								
								<div class="share-container">
									<p class="mb-2">Bagikan:</p>
									<button id="btn-product-share-fb" class="btn" @click="fb_share('Coba cek produk ini deh :'+product.name+'. Bisa gratis ongkir lho','https://morefurniture.id/product/'+product.slug)" target="_blank"> <i class="fa-brands fa-facebook fa-2x"></i> </button>
									<a id="btn-product-share-wa" class="btn" :href="'https://api.whatsapp.com/send/?phone&text=Coba cek produk ini deh :'+product.name+'. Bisa gratis ongkir lho. https://morefurniture.id/product/'+product.slug" target="_blank"> <i class="fa-brands fa-whatsapp fa-2x"></i> </a>
									<!-- <button id="btn-product-share-ig" class="btn" target="_blank"> <i class="fa-brands fa-instagram fa-2x"></i> </button> -->
									<button @click="copyLinkWa()" id="btn-product-share-link" class="btn"> <i class="fa-solid fa-link fa-xl"></i> </button>
								</div>
							</div>
							<div class="col-12 px-lg-5">
								<p class="product-description-title">
									Deskripsi Produk
								</p>

								<img v-for="image in product.r_uni_product_infos" :key="'image-info-'+image.id" :srcset="image.image_url" alt="" style="margin-top:20px;width:100%;" class="ol-image_deskripsi"  />
								<img v-for="image in product.r_uni_product_details" :key="'image-detail-'+image.id" :srcset="image.image_url" alt="" style="margin-top:20px;width:100%;" class="ol-image_deskripsi"  />
								<iframe v-for="video in product.r_uni_product_videos" :key="'video-yt-'+video.id" style="margin-top:20px;width:100%;height:450px;" class="iframe-youtube" :src="video.youtube_url" allow="fullscreen;"> </iframe>
								<img v-for="image in product.r_uni_product_suasanas" :key="'image-suasana-'+image.id" :srcset="image.image_url" alt="" style="margin-top:20px;width:100%;" class="ol-image_deskripsi"  />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Login Block Modal -->
		<div class="modal fade loginBlock" id="loginBlockModal" aria-labelledby="loginBlockModalLabel" aria-hidden="true" >
			<div class="modal-dialog modal-dialog-centered modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="loginBlockModalLabel">
							Perlu Login
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" ></button>
					</div>
					<div class="modal-body">
						Untuk mengakses fitur ini, anda harus login ke website
						utama.
					</div>
					<div class="modal-footer">
						<a href="/login" id="login-btn" type="button" class="btn btn-lg btn-primary btn-block" style="width:100%;">
							Login
						</a>
					</div>
				</div>
			</div>
		</div>

		<!-- Bendroom Modal -->
		<div class="modal fade bedroom" id="bedroomModal" aria-labelledby="bedroomModalLabel" aria-hidden="true">
			<div class="modal-dialog modal-dialog-centered modal-sm">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title" id="bedroomModalLabel">
							Pemberitahuan
						</h5>
						<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
					</div>
					<div class="modal-body">
						<div class="row">
							<div class="col-12 mb-4">
								Nantikan! Pameran Area Kamar Tidur akan hadir di 9 Mei 2022.
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Coohom Modal -->
		<div class="coohom-container">
			<div class="loader">
				<i class="fas fa-spinner fa-spin fa-3x"></i>
			</div>
			<div class="coohom-nav">
                <button id="coohom-close-button" class="btn btn-primary">
					<i class="fas fa-arrow-left"></i>
					<!-- <span class="non-mobile-label">&nbsp;&nbsp;Exhibition & Shopping</span> -->
				</button>
				&nbsp;&nbsp;
				<button id="coohom-menu-toggle-btn" class="btn btn-secondary">
					<i class="fas fa-bars"></i>
				</button>
				&nbsp;&nbsp;
				<button id="coohom-product-list-btn" class="btn btn-secondary" @click="openProductList()">
					Beli Produk
					<!-- <i class="fas fa-search"></i>
					<span class="non-mobile-label">&nbsp;&nbsp;Beli Produk</span> -->
				</button>
			</div>
			<iframe id="coohom-iframe" src="" frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen></iframe>
		</div>

		<!-- Tutorial Coohom Modal -->
		<div class="modal fade welcome" id="tutorialModal" aria-labelledby="tutorialModalLabel" aria-hidden="true" style="background:#000000cc;">
			<div class="modal-dialog modal-dialog-centered modal-md">
				<div class="modal-content" style="background: transparent; box-shadow: none; border: 0px;">
					<div class="modal-body">
						<div id="tutorial-coohom">
							<div id="tutorial-coohom-inside">
								<div class="tutorial-slider-exhb">
									<div class="tutorial-slide">
										<img class="img-fluid" :src=" asset('moreverse/tutorial/1.webp') " />
									</div>
									<div class="tutorial-slide">
										<img class="img-fluid" :src=" asset('moreverse/tutorial/2.webp') " />
									</div>
								</div>
							</div>
							<button class="btn prev">
								<i class="fa-solid fa-chevron-left"></i>
							</button>
							<button class="btn next">
								<i class="fa-solid fa-chevron-right"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div id="loading-progress">
			<div class="text-center">
				<i class="fas fa-spinner fa-spin fa-3x"></i>
				<br />
				<br />
				<span id="loading-percentage">5%</span>
			</div>
		</div>

		<div id="opening-video-overlay">
			<div class="opening-video-container ratio ratio 1x1">
				<video id="opening-video" playsinline>
					<source
						:src="asset('moreverse/data/video/Opener_MGX_1_compressed.mp4')"
						type="video/mp4"
					/>
					Your browser does not support the video tag.
				</video>
			</div>
			<button id="btn-skip-opening" class="btn btn-outline-light">
				SKIP
			</button>
			<button id="btn-play-opening">
				<span class="moreverse">MOREVERSE</span><br />CLICK TO ENTER
			</button>
		</div>
	</div>
</template>

<script>
const MOREVERSE_CDN = process.env.VUE_APP_BASE_CDN_URL + '/MORE/moreverse/'

import { mapActions, mapGetters } from "vuex";
import SkeletonLoader from '@/components/loading/skeletonLoader'

export default {
	name: "Moreverse",
	head: {
		link: [
			{ rel: 'stylesheet', href: 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.min.css', crossorigin: 'anonymous'},
			{ rel: 'preconnect', href: 'https://fonts.googleapis.com'},
			{ rel: 'preconnect', href: 'https://fonts.gstatic.com', crossorigin: 'anonymous'},
			{ rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Fredoka&display=swap'},
			{ rel: 'stylesheet', href: '/mverse/plugins/fontawesome/css/all.min.css'},
			{ rel: 'stylesheet', href: '/mverse/plugins/tiny-slider/tiny-slider.css'},
			{ rel: 'stylesheet', href: '/mverse/plugins/splide/css/splide.min.css'},
			{ rel: 'stylesheet', href: '/mverse/css/style.css'},
		],
		script: [
		    // { src: '/mverse/plugins/pixi/pixi.min.js', async: true },
		    { src: 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.6.0/jquery.min.js' },
		    { src: 'https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/js/bootstrap.bundle.min.js', crossorigin: 'anonymous' },
		    { src: '/mverse/plugins/moment/moment.js' },
		    { src: '/mverse/plugins/tiny-slider/min/tiny-slider.js' },
		    { src: 'https://cdn.jsdelivr.net/npm/masonry-layout@4.2.2/dist/masonry.pkgd.min.js', crossorigin: 'anonymous', async: true },
		    { src: '/mverse/plugins/splide/js/splide.min.js' },
		    // { src: '/mverse/plugins/pixi/pixi-projection.js' },
		    // { src: '/mverse/plugins/pixi/pixi-layers.js' },
		    // { src: '/mverse/plugins/pixi/pixi-sound.js' },
		    // { src: '/mverse/plugins/tween/tween.umd.js' },
		    { src: '/app.js', type: "module", crossorigin: 'anonymous', async: false },
		]
	},
	components:{
		SkeletonLoader,
	},
	data() {
		return {
			exhibitionAnnotations: [],
			activityAnnotations: [],
			storeAnnotations: [],
            productList:{
                loading: false,
                queue: false,
                q: "",
                is_bundle: "",
                filteredBy:this.$store.state.product.list.filtered,
                list:{
                    more: false,
                    total:0,
                },
            },
			productDetail:{
				loading: false,
				loading2Cart: false,
				queue: false,
			},
			cart:{
				note: "",
				loading: false,

			},
			chats:{
				loading: true,
				loading_more:false,
				load_more:false,
				history_scrollTop:0,
				data:[],
				page:1,
				per_page:10,
				msg: '',
				readQueued:null,
			},
			filterActivity: {
				category: '',
				duration: '',
			}
		};
		
	},
	computed: {
        ...mapGetters("auth", ["authenticated"]),
		...mapGetters("moreverse", ["activity", "notifications"]),
        ...mapGetters("product/list",["products","filtered"]),
        ...mapGetters("product/detail",["product","getSelectedFinalVariant"]),
		...mapGetters("transaction/cart", ["variants","grandtotal","outOfStock","note"]),
		...mapGetters("chats", ["inbox"]),
		...mapGetters("home", ["metaPages"]),
	},

	methods: {
        ...mapActions("moreverse", ["fetchActivity", "fetchNotifications", "setReminder"]),
        ...mapActions("product/list",["fetchProducts"]),
        ...mapActions("product/detail",["fetchProduct"]),
        ...mapActions("transaction/cart",{"saveCart": "save"}),
        ...mapActions("transaction/cart",["checkStock", "cartFbPixel"]),
		...mapActions("chats", {"fetchChats": "fetchData"}),
		...mapActions("chats", ['saveChat','read']),
		...mapActions("home", ["fetchMetaPages"]),
		...mapActions('profile/product', ['saveProductHistory']),

        async getProducts(firstPage = false, per_page=null){
            this.productList.filteredBy.room_type = localStorage.getItem("roomTypeActive");
            this.productList.filteredBy.q = this.productList.q;
            this.productList.filteredBy.is_bundle = this.productList.is_bundle;

            this.productList.loading = true;
            if(firstPage) this.productList.filteredBy.page = 1;            
            if(per_page != null) this.productList.filteredBy.per_page = per_page;

            this.$store.commit("product/list/setFiltered",this.productList.filteredBy);
            
            const results = await this.fetchProducts();
            this.productList.loading = false;
            if(results.success){
                this.productList.total = results.data.total; 
                this.productList.more = results.data.pagination.more;
                if(this.productList.more) {
                    this.productList.filteredBy.page = this.productList.filteredBy.page+1;
                    this.$store.commit("product/list/setFiltered",{page:this.productList.filteredBy.page});
                }
            }else{
                this.$toast.open({ message: "Gagal mendapatkan data produk", type: "error", duration: 3000, })
            }
        },

        async fetchProductsWithRateLimiter(usingQueue=true, withLoading=false){
            if(this.productList.queue != null) clearTimeout(this.productList.queue);
            if(usingQueue){
                this.productList.queue = setTimeout(async () => {
                    if(withLoading) this.productList.loading = true;
                    await this.getProducts(true);
                    if(withLoading) this.productList.loading = false;
                }, 900);
            }else{
                if(withLoading) this.productList.loading = true;
                await this.getProducts(true);
                if(withLoading) this.productList.loading = false;
            }	
		},

        openProductList(){
            $('#shopModal').modal('show');
            this.getProducts(true);
        },
		
        async openProduct(slug = null){
			if(slug){
				this.productDetail.loading = true;
				$('#shopDetailModal').modal('show');
				await this.fetchProduct(slug);
				this.productDetail.loading = false;
				var type = this.product.variants_available.length > 0 ? 1 : 0;
				this.setVariant(type, this.product.r_uni_product_variants[0]);
				
				this.initSplide();
				if(this.authenticated) await this.saveProductHistory();
			}
        },

		setVariant(type = 1, variant){
			if(type == 1){
				this.$store.commit("product/detail/setSelectedVariant", variant);
			}else{
				this.$store.commit("product/detail/setSelectedColor", variant);
			}
			$("#btnradio-"+variant.id).prop("checked",true);
			$(".thumb-slider-variant-"+variant.id+":first").trigger("click");
		},
		
		removeVariant(variant){
			// if(!this.chats.loadingCart){
			Swal.fire({
				text: "Hapus dari keranjang ?",
				icon: 'warning',
				showCancelButton: true,
				showCloseButton: true,
				confirmButtonColor: '#364b7b',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Hapus',
				cancelButtonText: 'Tidak',
			}).then(async (result) => {
				if (result.isConfirmed) {
					this.$store.commit('transaction/cart/remove', variant);
					this.synchingCart(false);
				}
			});
			// }
		},
		async toCart(){
			if(this.getSelectedFinalVariant){
				this.productDetail.loading2Cart = true;
				this.$store.commit('transaction/cart/plus2Cart', this.getSelectedFinalVariant);

				// let result = await this.checkStock(this.getSelectedFinalVariant);
				this.productDetail.loading2Cart = false;
				// if(result.success){
					$("#alert-cart-success").fadeIn().delay(5000).fadeOut();
					this.synchingCart();
				// } 
				// this.analytics('event', 'add_to_cart', {
                //     "items": [
                //         {
                //         "id": this.getSelectedFinalVariant.sku_item,
                //         "name": this.getSelectedFinalVariant.r_uni_product.name,
                //         "brand": this.getSelectedFinalVariant.r_uni_product.brand,
                //         "variant": this.getSelectedFinalVariant.variant ? this.getSelectedFinalVariant.variant : this.getSelectedFinalVariant.color,
                //         "price": this.getSelectedFinalVariant.valid_price,
                //         "list_position": 1,
                //         "quantity": 1,
                //         }
                //     ]
                // });

                this.cartFbPixel({
                    "browser": this.$browserDetect.meta.name+ " version: " +this.$browserDetect.meta.version,
                    "sku": this.getSelectedFinalVariant.sku_item,
                    "price": this.getSelectedFinalVariant.valid_price,
                    "product": this.getSelectedFinalVariant.name_short,
                });

				if(!result.success) $("#alert-cart-danger").fadeIn().delay(3000).fadeOut();
			}
        },
		async synchingCart(usingQueue = true){
			if(this.authenticated){
				// console.log("synchingCart");
				if(this.productDetail.queue != null) clearTimeout(this.productDetail.queue);
				if(usingQueue){
					this.productDetail.queue = setTimeout(async () => {
						await this.saveCart();
					}, 250);
				}else{
					await this.saveCart();
				}
			}
		},

		openCart(){
			$('#shoppingCartModal').modal('show');
		},
		openCartDetailProduct(){
			$('#shopDetailModal').modal('hide');
			$('#shopModal').modal('hide');
			$('#shoppingCartModal').modal('show');
		},
		
		plus(variant){
			if(!this.cart.loading){
				this.$store.commit('transaction/cart/plus', variant)
				// console.log(variant.quantity)
				this.synchingCart(true, true);
			}
		},
		min(variant){
			if(!this.cart.loading){
				this.$store.commit('transaction/cart/min', variant)
				this.synchingCart(true, true);
			}
		},
		fb_share(e,o){
            return window.open("http://www.facebook.com/sharer.php?u="+encodeURIComponent(o)+"&t="+encodeURIComponent(e),"sharer","toolbar=0,status=0,width=626,height=436"),!1
        },
		copyLinkWa() {
			// console.log("copy link");
			var dummy = document.createElement('input'),
			text = window.location.href;
			text = "https://morefurniture.id/product/"+this.product.slug;
			document.body.appendChild(dummy);
			dummy.value = text;
			dummy.select();
			document.execCommand('copy');
			document.body.removeChild(dummy);
			$('#copy_url').prop('hidden',false);
			// setTimeout(() => { $('#copy_url').prop('hidden',true); }, 1500);
			$("#alert-cart-info").fadeIn().delay(1500).fadeOut();
		},
		currentUrl(){
            return 'https://morefurniture.id'+this.$route.fullPath;
            // return window.location.origin+this.$route.fullPath;
        },
		initSplide(){
			var main = new Splide('#main-slider', {
				type: 'fade',
				rewind: true,
				pagination: false,
				arrows: false,
			});

			var thumbnails = new Splide('#thumbnail-slider', {
				fixedWidth: 80,
				fixedHeight: 80,
				gap: 10,
				rewind: true,
				pagination: false,
				cover: true,
				isNavigation: true,
				breakpoints: {
					600: {
					fixedWidth: 50,
					fixedHeight: 50,
					},
				},
			});

			main.sync(thumbnails);
			main.mount();
			thumbnails.mount();
		},
		openChats(){
			if(this.authenticated){
				$('#chatModal').modal('show');
				this.getDataChats();
			}else{
				$('#loginConfirmModal').modal('show');
			}
		},
        jqScrolling2top(){
            //$("a#a-footer-to-top").trigger("click");
            $("html, body").animate({ scrollTop: 0 }, "slow"); return false;
        },
        scrollToEnd: function () {
            // scroll to the start of the last message
            // var container = this.$el.querySelector("#m-b-0");
            // console.log(container.scrollHeight);

            // this.$refs.list.scrollTop =   container.scrollHeight;
			$("#chatModal .modal-body").animate({ scrollTop: $('#chatModal .modal-body').prop("scrollHeight")}, 'fast');
        },
        initScroll(){
            if($(".chat-stream").scrollTop() === 0) {
                // var container = this.$el.querySelector("#m-b-0");
                // this.chats.history_scrollTop = container.scrollHeight;
                // console.log(this.chats.load_more); 
                if(this.chats.load_more){
                    this.chats.page++;
                    this.chats.loading_more =true;
                    this.getDataChats();
                }
                
            }
        },
        onEnter: function() {
            this.scrollToEnd();
            // console.log(this.chats.msg);
            let pesan = this.chats.msg;
            this.chats.msg = '';
            if( pesan != ''){
                this.saveChat({text:pesan}).then(result =>{
                    let response = result.data;
                    this.chats.data = [...[response],...this.chats.data];
					console.log(this.chats.data);
                    this.scrollToEnd();

                }).catch(error =>{
                    // console.log(error);
                });
            }
        },
        async getDataChats(){
            this.chats.loading=true;
            this.jqScrolling2top();
            let data={
                page: this.chats.page,
                per_page:this.chats.per_page
            }
            await this.fetchChats(data).then((result) =>{
                this.chats.loading = false;
                this.chats.loading_more = false;
                let response = result.data;
                this.chats.load_more = response.next_page_url ? true: false;
                // this.chats.data = [...this.chats.data,...response.data];
                this.chats.data = [...response.data];
                // console.log(this.chats.data);
                if(this.chats.page != 1){
                    // var container = this.$el.querySelector("#m-b-0");
                    // this.$refs.list.scrollTop = container.scrollHeight - this.chats.history_scrollTop;
                }
                
            }).catch((error)=>{
                this.chats.loading = false;
                // console.log(error);
            });

			this.scrollToEnd();
			this.queueReadChats();

        },
        queueReadChats(usingQueue = true){
            if(this.chats.readQueued != null) clearTimeout(this.chats.readQueued);
            if(usingQueue){
                this.chats.readQueued = setTimeout(async () => {
                    this.read();
                }, 500);
            }else{
                this.read();
            }
        },
		openNotif(){
			$('#notificationModal').modal('show');
			this.fetchNotifications();
		},
		openReminder(activity_id){
			if(!this.authenticated){
				$('#loginBlockModal').modal('show');
			}else{
				this.setReminder(activity_id);
			}
		}
	},
    watch:{
        "productList.q": function(value){
            // console.log(value);
            this.fetchProductsWithRateLimiter();
        },
        "productList.is_bundle": function(value){
            // console.log(value);
            this.fetchProductsWithRateLimiter();
        },
		"cart.note": function(value){
			this.$store.commit('transaction/cart/setNote', value);
        },
		"filterActivity": {
			handler: async function(filterActivity) {
				await this.fetchActivity(filterActivity);
			},
			deep: true
		},
    },
	mounted: async function() {
		this.fetchMetaPages();
		this.cart.note = this.note;
		await this.fetchActivity();
		await this.fetchNotifications();
		if(this.authenticated){
			this.getDataChats();
		}


		// var tutorialSliderExhb = tns({
		// 	container: '.tutorial-slider-exhb',
		// 	controlsPosition: "bottom",
		// 	gutter: 10,
		// 	touch: true,
		// 	mouseDrag: true,
		// 	prevButton: '.tutorial-exhb .prev',
		// 	nextButton: 'tutorial-exhb. .next',
		// 	loop: false
		// });
	},
	updated() {
        // whenever data changes and the component re-renders, this is called.
		this.fetchMetaPages();
        this.$nextTick(() => {if(this.chats.page == 1) this.scrollToEnd()});
        
    },
	metaInfo() {
		
        var meta =  _.find(this.metaPages, {"slug":"moreverse"});
		return {
			title: meta ? meta.meta_title : "",
			meta: [
				// { name: 'title', content: this.currentMeta ? this.currentMeta.meta_title : ""},
				{ name: 'description', content: meta ? meta.meta_desc : "" },
			],
            link: [
                {rel: 'canonical', href: window.location.origin+this.$route.fullPath}
            ]
		}
    },
};
</script>
